import React, { useState, useEffect } from "react";
import { Form, notification, Checkbox } from "antd";
import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ErrorModal from "../../components/ErrorModal";
import { PLAYLIST_IS_PROTECTED } from "../../config";
import REQUESTS from "../../api/requests";
import useTranslation from "../../hooks/useTranslation";
import TEXT from "../../config/text";
import style from "./style/style.module.scss";

export default function AddPlaylistByFile({
  getPlaylists,
  deviceMac,
  onCancel,
  form,
}) {
  const translation = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null);

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isProtected, setIsProtected] = useState(PLAYLIST_IS_PROTECTED);

  // const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.playlist?.[text]) {
      return translation?.pages?.playlist?.[text];
    }

    return TEXT?.pages?.playlist?.[text];
  };

  const onFinish = (values) => {
    setErrorMessage(null);

    const { name, pin, confirm_pin, epg_url } = values;

    if (!file) {
      notification.error({
        description: "Please select m3u8 file",
      });

      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("mac", deviceMac);

    if (file) {
      formData.append("image", file);
    }

    formData.append("is_protected", isProtected);

    if (isProtected) {
      formData.append("pin", pin);
      formData.append("confirm_pin", confirm_pin);
    }

    if (epg_url) {
      formData.append("epg_url", epg_url.split(" ").join(""));
    }

    function callback(data) {
      setLoading(false);
      setFile(null);
      getPlaylists();
      onCancel();
      form.resetFields();
      setIsProtected(PLAYLIST_IS_PROTECTED);
    }
    function errorCallback(error) {
      setErrorMessage(error.message);

      setLoading(false);
    }

    REQUESTS.UPLOAD_PLAYLIST(formData, callback, errorCallback);
  };

  useEffect(() => {
    form.resetFields();
  }, []);
  return (
    <div>
      <Form
        autoComplete="off"
        name="upload-playlist-by-file"
        form={form}
        onFinish={onFinish}
        className={style["add-modal-form"]}
      >
        <div style={{ width: "100%" }}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: handleText("playlist_name_message"),
              },
            ]}
          >
            <InputComponent placeholder={handleText("playlist_name")} />
          </Form.Item>

          <Form.Item
            name="file"
            rules={[
              {
                required: true,
                message: handleText("playlist_file_message"),
              },
            ]}
          >
            <InputComponent
              type="file"
              value={file}
              name={file?.name}
              placeholder={"Please select file"}
              accept=".m3u8, m3u"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
          </Form.Item>
          <Form.Item
            name="epg_url"
            rules={[
              {
                validator: (rule, value, callback) => {
                  if (value && !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value)) {
                    callback('Please enter a valid URL');
                  } else {
                    callback();
                  }
                }
              },
            ]}
          >
            <InputComponent placeholder={handleText("playlist_epg_url")} />
          </Form.Item>

          {!PLAYLIST_IS_PROTECTED && <Checkbox
            checked={isProtected}
            onChange={(e) => setIsProtected(e.target.checked)}
            className={style["protected-playlist-checkbox"]}
          >
            {handleText("protect_playlist")}
          </Checkbox>}
          <div>
            <Form.Item
              name="pin"
              rules={[
                {
                  required: isProtected,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <InputComponent
                type="password"
                placeholder={handleText("set_pin")}
                disabled={!isProtected}
              />
            </Form.Item>

            <Form.Item
              name="confirm_pin"
              dependencies={["pin"]}
              hasFeedback
              rules={[
                {
                  required: isProtected,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("pin") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <InputComponent
                type="password"
                placeholder={handleText("confirm_pin")}
                disabled={!isProtected}
              />
            </Form.Item>
          </div>

          <div style={{ marginTop: "40px", textAlign: "center" }}>
            <ButtonComponent
              loading={loading}
              onClick={() => form.submit()}
              title={handleText("Upload")}
            />
          </div>
        </div>
      </Form>
      <ErrorModal
        message={errorMessage}
        visible={errorMessage ? true : false}
        onClose={() => setErrorMessage(null)}
      />
    </div>
  );
}
